import React from 'react'

export default function Loader() {
    return (
        <div className='loader_fix flex-column'>
            <div className='d-flex mb-3'>
                <div className="spinner-grow text-warning " role="status">
                    <span className="sr-only">Loading...</span>
                </div>
                <div className="spinner-grow text-warning mx-2" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
                <div className="spinner-grow text-warning" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
            <h5 className='text-light fs-2' style={{ textShadow:"0px 0px 20px #ffcc26" }}>Please Wait...</h5>
        </div>
    )
}
