


import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function OrderDailog(props) {
    const { open, setOpen, Orderid } = props


    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <i className='text-success'>
                        {"Success"}
                    </i>
                </DialogTitle>
                <hr className='mt-0' />
                <DialogContent className='pt-0'>
                    <DialogContentText id="alert-dialog-description">
                        <b className='text-dark'>
                            Order Created Successfully
                        </b>
                        <br />
                        <i className="text-warning fw-bold">NOTE : <span className='text-danger'>Please Note Your OrderID For Future Reference. </span> </i> <br />
                        <span className='text-dark fw-bold'>
                            Your Order ID Is <i style={{ textDecoration:"underline" }}>#{Orderid}</i>
                        </span>

                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button className='fw-bold' onClick={handleClose} autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
