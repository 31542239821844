import React, { useEffect } from 'react'
import { useState } from 'react'
import { useSnackbar } from 'notistack';
import axios from 'axios';
import OrderDailog from './Orderdilog';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Loader from '../Components.js/Loader';
import moment from 'moment';


export default function ChackOut() {
    if (`${localStorage.getItem("preURl")}` !== null) {
        if (localStorage.getItem("preURl") !== window.location.hostname) {
            localStorage.removeItem("name")
            localStorage.removeItem("phone")
            localStorage.removeItem("address")
        }
    } else {
        localStorage.removeItem("name")
        localStorage.removeItem("phone")
        localStorage.removeItem("address")
    }
    const { enqueueSnackbar: showSnackbar } = useSnackbar();
    const [AllProducts, setAllProducts] = useState([]);
    const [ProductDetails, setProductDetails] = useState({})
    const [isOnline, setisOnline] = useState(true);
    const [CustomerDetails, setCustomerDetails] = useState({
        name: window.location.search ? decodeURI(window.location.search.split("name=")[1].split("&phone=")[0]) : "",
        phone: window.location.search ? decodeURI(window.location.search.split("phone=")[1]) : "",
        address: "",
        state: "",
        city: "",
        pincode: "",
        email: "",
        postOffice: ""
    });
    const [success, setsuccess] = useState(false);
    const [Orderid, setOrderid] = useState("");
    const [isLoading, setisLoading] = useState(false);
    const [Reviews, setReviews] = useState([]);

    const GetPostOffice = async (e) => {
        let postalcode = e.target.value
        setCustomerDetails({ ...CustomerDetails, pincode: e.target.value })
        if (postalcode.length === 6) {
            if (`${Number(postalcode)}` === "NaN") {
                return showSnackbar("Invalide Postal Code", { variant: "warning" })
            }
        }
        try {
            if (postalcode.length === 6) {
                setisLoading(true)
                const data = await axios.get(`https://api.postalpincode.in/pincode/${postalcode}`,)
                if (data.status === 200) {
                    setisLoading(false)
                    if (data.data[0].Status === "Success") {
                        setCustomerDetails({ ...CustomerDetails, pincode: e.target.value, state: data.data[0].PostOffice[0].State, city: data.data[0].PostOffice[0].District })
                    } else {
                        showSnackbar("No Records Found", { variant: "error" })
                    }
                } else {
                    setisLoading(false)
                    return showSnackbar("Failed To Fetch Post Office", { variant: "error" })
                }
            } else {
                setCustomerDetails({ ...CustomerDetails, state: "", city: "" })
            }
        } catch (err) {
            setisLoading(false)
            return showSnackbar("Failed To Fetch Post Office", { variant: "error" })
        }
    }


    const FetchProduct = async () => {
        try {
            setisLoading(true)
            const result = await axios.get(`${process.env.REACT_APP_API_URL}product/fetch_with_api_key?api_key=${process.env.REACT_APP_API_KEY}`, { headers: { "web_site_url": process.env.REACT_APP_URL } })
            if (result.status === 200) {
                setisLoading(false)
                document.title = result.data.product[0].name
                setAllProducts(result.data.product)
                return setProductDetails(result.data && result.data.product ? result.data.product.length > 0 ? result.data.product[0] : {} : {})
            }
            setisLoading(false)
            showSnackbar("Failed to Fetch Products", { variant: "error" })
        } catch (err) {
            setisLoading(false)
            return showSnackbar(err.response && err.response.data ? err.response.data.message : err.message, { variant: "error" })
        }
    }

    const FetchProductReviews = async () => {
        let proids = AllProducts.map((x) => x.id)
        proids = proids.join(",")
        try {
            setisLoading(true)
            let Reviews = await axios.get(`${process.env.REACT_APP_API_URL}reviews/proid`, {
                headers: {
                    "proid": proids
                }
            })
            setisLoading(false)
            if (Reviews.status === 200) {
                setReviews(Reviews.data.reviews)
            }
        } catch (err) {
            setisLoading(false)
            showSnackbar("Reviews Not Found", { variant: "info" })
        }

    }


    useEffect(() => {
        FetchProduct()
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (AllProducts && AllProducts.length > 0) {
            FetchProductReviews()
        }
        // eslint-disable-next-line
    }, [AllProducts]);



    const initPayment = (data, OrderId) => {
        setisLoading(true)
        const options = {
            key: data.razorpay_key_id,
            amount: data.amount,
            currency: data.currency,
            name: data.product_name,
            description: "Test Transaction",
            image: "media/img-1.jpg",
            order_id: data.id,
            notes: { "merchant_order_id": "BHK-" + OrderId },
            prefill: {
                name: data.customer_name,
                email: data.customer_email,
                contact: data.customer_phone,
            },
            handler: async (response) => {
                response["receipt"] = data.receipt
                try {
                    const { data } = await axios.post(`${process.env.REACT_APP_API_URL}order/verify?api_key=${process.env.REACT_APP_API_KEY}`, response, { headers: { "web_site_url": process.env.REACT_APP_URL } })
                    if (data.message === "Payment verified successfully") {
                        setisLoading(false)
                        showSnackbar("Payment verified Success", { variant: "success" })
                        setsuccess(true)
                    } else {
                        setisLoading(false)
                        showSnackbar("Payment Varification Failed", { variant: "error" })
                    }
                } catch (err) {
                    setisLoading(false)
                    showSnackbar("Payment Varification Failed", { variant: "error" })
                }
            },
            theme: {
                color: "#3399cc"
            }
        }
        const rzp1 = new window.Razorpay(options)
        rzp1.on('payment.failed', function (response) {
            options.handler(response)
        });
        rzp1.open()
        setisLoading(false)
    }





    const PostonClick = async (e) => {
        e.preventDefault();
        const data = {
            customer_name: CustomerDetails.name,
            customer_phone: CustomerDetails.phone,
            customer_email: CustomerDetails.email,
            customer_address: CustomerDetails.address,
            pincode: CustomerDetails.pincode,
            state: CustomerDetails.state,
            city: CustomerDetails.city,
            postOffice: null,
            product: ProductDetails.id,
            product_name: ProductDetails.name,
            price: ProductDetails.price,
            discount: ProductDetails.discount,
            totalPrice: ProductDetails.price - ProductDetails.discount,
            payment_mode: isOnline ? "online" : "cod",
            form_id: null
        }
        if (data.customer_name === "" || `${data.customer_name}` === "null" || typeof data.customer_name === "undefined") {
            return showSnackbar(`Required Field Name Is Empty`, { variant: "error" })
        }
        if (data.customer_phone === "" || `${data.customer_phone}` === "null" || typeof data.customer_phone === "undefined") {
            return showSnackbar(`Required Field Phone Is Empty`, { variant: "error" })
        }
        if (data.customer_phone.length !== 10) {
            return showSnackbar("Please Enter valid Phone Number", { variant: "error" })
        }
        if (data.customer_phone[0] !== "9" && data.customer_phone[0] !== "8" && data.customer_phone[0] !== "7" && data.customer_phone[0] !== "6") {
            return showSnackbar("Please Enter a Valide Phone Number", { variant: "error" })
        }
        if (!/^\d+$/.test(data.customer_phone)) {
            return showSnackbar("Please Enter a Valide Phone Number", { variant: "error" })
        }
        if (data.customer_phone[0] === "0" || data.customer_phone[0] === "1" || data.customer_phone[0] === "2" || data.customer_phone[0] === "3" || data.customer_phone[0] === "4" || data.customer_phone[0] === "5") {
            return showSnackbar(`Your Phone Number Is Invalide Please Chake Your Phone Number`, { variant: "error" })
        }
        if (data.customer_address === "" || `${data.customer_address}` === "null" || typeof data.customer_address === "undefined") {
            return showSnackbar(`Required Field Address Is Empty`, { variant: "error" })
        }
        if (data.customer_email === "" || `${data.customer_email}` === "null" || typeof data.customer_email === "undefined") {
            return showSnackbar(`Required Field Email Is Empty`, { variant: "error" })
        }
        if (data.pincode === "" || `${data.pincode}` === "null" || typeof data.pincode === "undefined") {
            return showSnackbar(`Required Field Postal-code Is Empty`, { variant: "error" })
        }
        if (data.state === "" || `${data.state}` === "null" || typeof data.state === "undefined") {
            return showSnackbar(`Required Field State Is Empty`, { variant: "error" })
        }
        if (data.city === "" || `${data.city}` === "null" || typeof data.city === "undefined") {
            return showSnackbar(`Required Field City Is Empty`, { variant: "error" })
        }

        try {
            setisLoading(true)
            const Result = await axios.post(`${process.env.REACT_APP_API_URL}order/create?api_key=${process.env.REACT_APP_API_KEY}`, data, { headers: { "web_site_url": process.env.REACT_APP_URL } })
            if (Result.status === 200) {
                setisLoading(false)
                showSnackbar("Order Created Successfully", { variant: "success" })
                if (data.payment_mode === "online") {
                    Result.data.data["customer_name"] = data.customer_name
                    Result.data.data["customer_email"] = data.customer_email
                    Result.data.data["customer_phone"] = data.customer_phone
                    setOrderid(Result.data.OrderId)
                    initPayment(Result.data.data, Result.data.OrderId)
                } else {
                    setsuccess(true)
                    setOrderid(Result.data.OrderId)
                }
            } else {
                setisLoading(false)
                return showSnackbar("Faild To Create Order", { variant: 'error' })
            }
            localStorage.removeItem("name")
            localStorage.removeItem("phone")
            localStorage.removeItem("address")
        } catch (err) {
            localStorage.removeItem("name")
            localStorage.removeItem("phone")
            localStorage.removeItem("address")
            setisLoading(false)
            return showSnackbar("Faild To Create Order", { variant: "error" })
        }
    }

    const [WindowWidth, setWindowWidth] = useState(1000);
    useEffect(() => {
        window.addEventListener("resize", function () {
            setWindowWidth(window.innerWidth);
        });
        // eslint-disable-next-line
    }, [window]);



    return (
        <>

            <OrderDailog open={success} Orderid={Orderid} setOpen={setsuccess} />
            {
                isLoading ? (
                    <Loader />
                ) : null
            }
            <div className="w-100 main">
                <div className="container pt-3 pb-5  custom-container">
                    <h1 className='head-1 mb-4'>Checkout Your Order</h1>
                    <hr />
                    <div className="row m-0">
                        <div className="col-12 ">
                            <div className="row m-0">
                                <div className="col-md-6 col-sm-12 mb-3 ">
                                    <div className="row">
                                        {
                                            WindowWidth > 768 ? (

                                                <div className="col-12 mb-2">
                                                    <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
                                                        <div className="carousel-indicators">
                                                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                                                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                                                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 4"></button>
                                                        </div>
                                                        <div className="carousel-inner">
                                                            <div className="carousel-item active">
                                                                <img src="./media/img-1.jpg" className="d-block" alt="..." />
                                                            </div>
                                                            <div className="carousel-item">
                                                                <img src="./media/img-2.jpg" className="d-block " alt="..." />
                                                            </div>
                                                            <div className="carousel-item">
                                                                <img src="./media/img-3.jpg" className="d-block " alt="..." />
                                                            </div>
                                                            <div className="carousel-item">
                                                                <img src="./media/img-4.jpg" className="d-block" alt="..." />
                                                            </div>
                                                        </div>
                                                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                                                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                                            <span className="visually-hidden">Previous</span>
                                                        </button>
                                                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                                                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                                            <span className="visually-hidden">Next</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            ) : ""
                                        }
                                        <div className="col-12">
                                            <div className="row m-0 offer-detail">
                                                <div className="col-12">
                                                    <h3 className='fw-bold'>Offers</h3>
                                                    <ul>
                                                        <li>Get 10% instant discount on online payment</li>
                                                        <li>COD available</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12 ">
                                    <h3 className='fw-bold mb-3'>
                                        {
                                            ProductDetails.name
                                        }
                                    </h3>
                                    <h6 className='fw-bold mb-3'>Male Walness</h6>
                                    <div className="row m-0">
                                        <div className="col-1 p-0">
                                            <img className='google-img' src="./media/google.svg" alt="" />
                                        </div>
                                        <div className="col-11 p-0">
                                            <p className='rat d-inline align-items-start '>4.2 </p>
                                            <div className="ratting d-inline ">
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star"></i>
                                                <i className="fa-solid fa-star-half"></i>
                                            </div>
                                        </div>
                                        <div className="col-12 d-flex align-items-center py-1 px-5">
                                            <img className='reviww-img' src="./media/verfieid_icon.svg" alt="" />&nbsp;
                                            <h5 className='custom-reviews m-0'> 588+ <span> reviews</span></h5>
                                        </div>
                                    </div>
                                    <hr />
                                    <h3 className='fw-bold'>Pack</h3>
                                    <div className="row m-0" style={{ columnGap: "1rem" }}>
                                        {
                                            AllProducts ? AllProducts.length > 0 ? AllProducts.map((x, key) => (
                                                <div key={x.id} className="col-md-5 col-sm-12 p-0 mb-3" style={{ paddingLeft: "0" }}>
                                                    <div className="card-container px-4 py-2" style={{ boxShadow: ProductDetails.id === x.id ? "0px 0px 7px 2px #fff" : "none", border: ProductDetails.id === x.id ? "2px solid #ffbc00" : "2px solid #ffffff", background: ProductDetails.id === x.id ? "#ffbc00" : "none", color: ProductDetails.id === x.id ? "black" : "#ffffff" }} onClick={() => {
                                                        setProductDetails({ ...x, discount: isOnline ? x.discount : 0 })
                                                    }} >
                                                        <p className=''><b className={ProductDetails.id === x.id ? 'card_title' : ""}>{x.title}</b></p>
                                                        <div className=" row mx-0 w-100">
                                                            <div className="col-6 px-0">
                                                                <p>{x.short_content}</p>
                                                                <p>Expiry-{x.expiry_year} Years</p>
                                                                <p className='old-price'><s style={{ color: ProductDetails.id === x.id ? "#ff401e" : "#ff401e", fontWeight: "900" }} > ₹ {x.reguler_price}</s></p>
                                                                <h4 style={{ color: ProductDetails.id === x.id ? "#00c200" : "#2aff2a", fontWeight: "900" }}>₹ {x.price}</h4>
                                                                <p style={{ position: "relative", top: 0, left: 0, zIndex: 10000, width: 110 }} className='save-rs'>Save <span style={{ fontWeight: "900" }}>₹-{x.reguler_price - x.price}/-</span></p>
                                                            </div>
                                                            {
                                                                WindowWidth < 768 ? key === AllProducts.length - 1 ? (
                                                                    <div className="col-6 px-0 d-flex justify-content-center align-items-start" style={{ gap: "0.5rem" }}>
                                                                        <img src="./media/k2.png" alt="img" width={"200"} height={200} />
                                                                    </div>

                                                                ) : (
                                                                    <div className="col-6 px-0 d-flex justify-content-center align-items-start" style={{ gap: "0.5rem" }}>
                                                                        <img src="./media/k.png" alt="img" width={"200"} height={200} />
                                                                    </div>
                                                                ) : ""
                                                            }
                                                        </div>

                                                    </div>
                                                </div>
                                            ))
                                                : null : null
                                        }
                                    </div>
                                    <hr className='offer-detail-2' />
                                    <div className="row m-0 offer-detail-2">
                                        <div className="col-12">
                                            <h3 className='fw-bold'>Offers</h3>
                                            <ul>
                                                <li>Get 10% instant discount on online payment</li>
                                                <li>COD available</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='form-container p-3' id='order'>
                        <h2 className='text-center fw-bold mb-5'>Billing details</h2>
                        <form className="row">
                            <div className="col-lg-4 col-md-6 col-sm-12 mb-3 ">
                                <label className='d-block'>Name</label>
                                <input type="text" value={CustomerDetails.name} onChange={(e) => {
                                    setCustomerDetails({ ...CustomerDetails, name: e.target.value })
                                }} placeholder='Enter Your Name' />
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12  mb-3 ">
                                <label className='d-block'>Email</label>
                                <input type="email" placeholder='Enter Your Email' value={CustomerDetails.email} onChange={(e) => {
                                    setCustomerDetails({ ...CustomerDetails, email: e.target.value })
                                }} />
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12  mb-3 ">
                                <label className='d-block'>Mobile</label>
                                <div className="w-100 d-flex justify-content-center align-items-center">
                                    <span className=" input-group-text fw-bold bg-light" style={{ padding: "1rem 0.5rem", borderTopRightRadius: "0px", borderBottomRightRadius: "0px", border: "1px solid #fff" }} id="basic-addon1">+91</span>
                                    <input type="phone" className="phone_input" style={{ borderTopLeftRadius: "0px", borderBottomLeftRadius: "0px", }} placeholder='Enter Your 10 Digit Mobile Number' maxLength={10} value={CustomerDetails.phone} onChange={(e) => {
                                        setCustomerDetails({ ...CustomerDetails, phone: e.target.value })
                                    }} />
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12  mb-3">
                                <label className='d-block'>Postal code</label>
                                <input type="text" maxLength={6} onChange={GetPostOffice} placeholder='PIN code' />
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12  mb-3">
                                <label className='d-block'>State</label>
                                <input type="text" disabled placeholder='State' value={CustomerDetails.state} />
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12  mb-3 ">
                                <label className='d-block'>City</label>
                                <input type="text" disabled placeholder='City' value={CustomerDetails.city} />
                            </div>
                            {/* <div className="col-12  mb-2 mt-1 ">
                                <select className='w-100 select' disabled={PostOffice.length <= 0} onChange={(e) => setCustomerDetails({ ...CustomerDetails, postOffice: e.target.value })}>
                                    <option value="" className='text-dark fs-5' >Select Post Office</option>
                                    {
                                        PostOffice ? PostOffice.length > 0 ? PostOffice.map((x, key) => (
                                            <option key={key} className='p-2 fs-5 text-dark' value={x.Name}>{x.Name}</option>
                                        )) : null : null
                                    }
                                </select>
                            </div> */}
                            <div className="col-12 mb-3 ">
                                <label className='d-block'>Address</label>
                                <input className='address-input' type="text" placeholder='Enter Your Full Address' value={CustomerDetails.address} onChange={(e) => {
                                    setCustomerDetails({ ...CustomerDetails, address: e.target.value })
                                }} />
                            </div>
                            <hr className='custom-hr' />
                            <div className='px-3'>
                                <FormControl fullWidth>
                                    <FormLabel id="demo-radio-buttons-group-label" className='text-light'>Choose Payment Mode</FormLabel>
                                    <RadioGroup
                                        value={isOnline ? "online" : "cod"}
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        defaultValue="female"
                                        name="radio-buttons-group"
                                        onChange={(e) => {
                                            if (e.target.value === "online") {
                                                setisOnline(true)
                                                let price = ProductDetails.price
                                                setProductDetails({ ...ProductDetails, discount: price * 10 / 100 })
                                            } else {
                                                setisOnline(false)
                                                setProductDetails({ ...ProductDetails, discount: 0 })
                                            }
                                        }}
                                    >
                                        <FormControlLabel value="online" className={isOnline ? "text-warning" : "text-light"} control={<Radio className={isOnline ? "text-warning" : "text-light"} />} label="CREDIT CARD / DEBIT CARD / NETBANKING" />
                                        <img className='img-tag' src="./media/card-image.jpg" alt="" />
                                        {
                                            ProductDetails.cod === 1 ? (
                                                <FormControlLabel value="cod" className={isOnline ? "text-light" : "text-warning"} control={<Radio className={isOnline ? "text-light" : "text-warning"} />} label="CASH ON DELIVERY" />
                                            ) : null
                                        }
                                    </RadioGroup>
                                </FormControl>
                            </div>
                            <hr className='custom-hr my-3' />
                            <div className="d-flex justify-content-between">
                                <h5>Subtotal</h5>
                                <h5>Rs.{ProductDetails.price}.00</h5>
                            </div>
                            <div className="d-flex justify-content-between">
                                <p>10% Discount on online payment</p>
                                <p>-Rs.{ProductDetails.discount}.00</p>
                            </div>
                            <div className="d-flex justify-content-between">
                                <h3>Total</h3>
                                <h3>Rs.{ProductDetails.price - ProductDetails.discount}.00</h3>
                            </div>

                            <div className="row m-0">
                                <div className="col-12 d-flex justify-content-end mt-5 ">
                                    <button type='button' onClick={PostonClick} className='chackout-btn'>Checkout</button>
                                </div>
                            </div>
                        </form>
                    </div>

                    <h2 className='head-1 mt-5 mb-2 text-start fw-bold fw-normal text-center mb-3'>Customer Speaks</h2>
                    <div className='form-container p-3'>
                        <center>
                            {
                                WindowWidth > 768 ? <>
                                    <iframe className='mb-2' width="65%" height="400px" src="https://www.youtube.com/embed/mdS8lNKyFSI" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                    <br />

                                    <iframe className='mb-2' width="65%" height="400px" src="https://www.youtube.com/embed/0ttJ6dF5Y-Y" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                    <br />
                                    <iframe width="65%" height="400px" src="https://www.youtube.com/embed/qSZnFXTdRUM" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                </> : <>
                                    <iframe className='mb-2' width="100%" height="200px" src="https://www.youtube.com/embed/mdS8lNKyFSI" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                    <br />
                                    <iframe className='mb-2' width="100%" height="200px" src="https://www.youtube.com/embed/0ttJ6dF5Y-Y" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                    <br />
                                    <iframe width="100%" height="200px" src="https://www.youtube.com/embed/qSZnFXTdRUM" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                </>
                            }


                        </center>


                    </div>


                    {
                        Reviews && Reviews.length > 0 ? (
                            <>
                                <h2 className='head-1 mt-5 text-center fw-normal fw-bold mb-3'>Customer Reviews</h2>
                                <div className='form-container p-3'>
                                    {
                                        Reviews ? Reviews.length > 0 ? Reviews.map((x, key) => (
                                            <div className="row" key={key}>
                                                <div className="col-md-1 col-sm-3 col-3">
                                                    <img src={x.profile_photo} alt="img" width={60} />
                                                </div>
                                                <div className="col-md-11 col-sm-9 col-9">
                                                    <h6 className="text-warning fw-bold d-flex align-items-center justify-content-start" style={{ gap: "0.5rem" }}>
                                                        <span>
                                                            {x.customer_name}
                                                        </span>
                                                        <span>
                                                            <img src="./media/verfieid_icon.svg" alt="img" width={20} />
                                                        </span>
                                                    </h6>
                                                    <i className="d-block mb-1" style={{ fontSize: "0.8rem", color: "#565656" }}>
                                                        {
                                                            moment(x.created_at).format("LL")

                                                        }
                                                    </i>
                                                    <p>
                                                        {x.comment}
                                                    </p>
                                                </div>
                                                {
                                                    key !== Reviews.length - 1 ? (
                                                        <hr />
                                                    ) : ""
                                                }
                                            </div>
                                        )) : "" : ""
                                    }
                                </div>
                            </>
                        ) : ""
                    }

                </div>
                <hr />
                <small className="text-center d-block pt-2 pb-3 fs-5 px-1">
                    © 2022 COPYRIGHT. ALL RIGHTS RESERVED.
                </small>
                <a className="text-center text-info d-block pb-3 px-1 fs-5" href='#order' style={{ textDecoration: "none", cursor: "pointer" }} >
                    PRIVACY POLICY | TERMS & CONDITION
                </a>
            </div>
        </>
    )
}
