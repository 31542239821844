
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import ChackOut from './screens/ChackOut';

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          <Route path='/' element={<ChackOut />} />
          {/* <Route path='/checkout' element={<ChackOut />} /> */}
        </Routes>
      </div >
    </BrowserRouter>
  );
}

export default App;
